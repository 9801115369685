import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,2,4,6];

export const dictionary = {
		"/": [11],
		"/2fa": [~31],
		"/(auth)/(admin)/companies": [~12,[2,4],[3,5]],
		"/(auth)/company": [~18,[2,6],[3,7]],
		"/(auth)/company/documents": [~20,[2,6],[3,7]],
		"/(auth)/company/entries": [~21,[2,6],[3,7]],
		"/(auth)/company/information": [~22,[2,6],[3,7]],
		"/(auth)/company/invoices": [~23,[2,6],[3,7]],
		"/(auth)/company/mailbox": [~24,[2,6],[3,7]],
		"/(auth)/company/payments": [~25,[2,6],[3,7]],
		"/(auth)/company/profile": [~26,[2,6],[3,7]],
		"/(auth)/company/questions": [27,[2,6],[3,7]],
		"/(auth)/company/reports": [~28,[2,6],[3,7]],
		"/(auth)/company/reports/[report]": [~29,[2,6],[3,7]],
		"/(auth)/company/users": [~30,[2,6],[3,7]],
		"/(auth)/company/[leftTables]": [~19,[2,6],[3,7,8]],
		"/(auth)/(admin)/globalData": [~13,[2,4],[3,5]],
		"/login": [~32],
		"/(auth)/(admin)/newCompany": [14,[2,4],[3,5]],
		"/questions/thanks": [34,[],[9]],
		"/questions/[token]": [33,[],[9]],
		"/recovery-pwd/[token]": [35,[],[10]],
		"/(auth)/(admin)/telescope": [15,[2,4],[3,5]],
		"/(auth)/(admin)/tools": [~16,[2,4],[3,5]],
		"/(auth)/(admin)/users": [~17,[2,4],[3,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';